import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import { TextInputField, GetInputField } from '../../../Elements';
import { checkString, checkEmail, checkMobileNumber, checkNumber } from '../../../../../formValidator';
import { trackActivity } from '../../../../../utils/mixpanel';
export default class AddEditContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {},
      error: {}
    }
  }
  componentDidMount() {
    this.setState({
      contact: this.props.contact.data
    });
  }
  updateForm = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      contact: {
        ...prevState.contact,
        [key]: value
      },
      error: {
        ...prevState.error,
        [key]: ''
      }
    }));
  }
  addEditContact = (e) => {
    const { contact } = this.state;
    e.preventDefault();
    const validateNewInput = {
      full_name: checkString({ value: contact.full_name, required: true, minLength: '', maxLength: '', message: 'Please enter Name' }),
      email: checkEmail({ value: contact.email, required: true, minLength: '', maxLength: '', message: 'Please enter valid Email' }),
      phone_number: contact.phone_number.trim() ? /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(contact.phone_number) ? '' : 'Please enter valid Phone Number' : '',
      fax: checkNumber({ value: contact.fax, required: '', minLength: '', maxLength: "", message: "Please enter valid Fax" })
    };

    let contactObj = Object.assign({}, this.state.contact);
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.updateSideBar(contactObj, this.props.contact.type);
    } else {
      this.setState({
        error: validateNewInput
      });
    }
  }
  render() {
    const { error, contact } = this.state;
    const { type } = this.props.contact;
    return (
      <div>
        <form onSubmit={(e) => this.addEditContact(e)}>
          <div className="sidebar-form" style={{ height: '100vh'}}>
            <Row>
              <Col md="12" style={{ marginBottom: '20px' }}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Name {type != 'view' ? <sup className="reqStar"> * </sup> : null}</label>,
                    {
                      type: type,
                      value: contact.full_name,
                      error: this.state.error.full_name,
                      updateForm: (value) => this.updateForm('full_name', value)
                    }
                  )
                }
              </Col>
              <Col md="12" style={{ marginBottom: '20px' }}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Email {type != 'view' ? <sup className="reqStar"> * </sup> : null}</label>,
                    {
                      type: type,
                      value: contact.email,
                      error: this.state.error.email,
                      updateForm: (value) => this.updateForm('email', value)
                    }
                  )
                }
              </Col>
              <Col md="12" style={{ marginBottom: '20px' }}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Phone Number</label>,
                    {
                      type: type,
                      value: contact.phone_number,
                      error: this.state.error.phone_number,
                      updateForm: (value) => this.updateForm('phone_number', value)
                    }
                  )
                }
              </Col>
              <Col md="12" style={{ marginBottom: '20px' }}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Designation</label>,
                    {
                      type: type,
                      value: contact.title,
                      updateForm: (value) => this.updateForm('title', value)
                    }
                  )
                }
              </Col>
              <Col md="12" style={{ marginBottom: '20px' }}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Fax</label>,
                    {
                      type: type,
                      value: contact.fax,
                      error: this.state.error.fax,
                      updateForm: (value) => this.updateForm('fax', value)
                    }
                  )
                }
              </Col>
            </Row>
          </div>
          {type != 'view' ?
            <div className="edit-sidebar-footer">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button
                    type="submit"
                    disabled={this.props.contactLoader}
                    className="btn btn-primary">{this.props.contactLoader ? 'Processing...' : 'Save'}</button>
                </li>
                <li className="list-inline-item">
                  <Link onClick={() => { this.props.toggleEditSideBar(); trackActivity('Project Management', { form_name: 'T006', page_title: 'Add/Edit Form', event_type: 'Clicked', event_desc: 'Clicked on Cancel button from ADD EDIT Form' }) }}> Cancel</Link>
                </li>
              </ul>
            </div> : null
          }
        </form>
      </div>
    )
  }
}
