import React from 'react';
import Popover from '@material-ui/core/Popover';

const PopoverComponent = (props) => {
  const { anchorEl, handleClose, children } = props
  const open = Boolean(anchorEl);

  return (
    <Popover open={open} anchorEl={anchorEl} onClose={handleClose}
      // anchorOrigin={{vertical: 'left',horizontal: 'right',}} 
      // transformOrigin={{vertical: 'left',horizontal: 'left',}}
      getContentAnchorEl={null}  // Important for dynamic positioning
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      // anchorOrigin={{
      //   vertical: "top",
      //   horizontal: "center",
      // }}
      // transformOrigin={{
      //   vertical: "top",
      //   horizontal: "center",
      // }}
    >
      {children}
    </Popover>
  );
}

export default PopoverComponent