import React, { useEffect, useState } from "react";
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSnackbar } from "notistack";
import { TableRow, TableCell, Paper, Tooltip, Chip, } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import { apiUrl, helperConst, projectTrackerFilter, projectTrackerTableHeader, projectStatusObj, assetType } from "..";
import ProjectInfoPopover from "./ProjectInfoPopover";
import FilterExportPanel from "./FilterExportPanel";
import AssetPopover from "./AssetPopover";
import RemarksPopover from "./RemarksPopover";
import { Popover, TableWrapper } from "../shared";
import useGetConstants from "../hooks/useGetConstants";
import useGetTrackerList from "../hooks/useGetTrackerList";
import { getLocalStorageInfo } from "../../../utils_v2";
import { EmptyCollection, ToolTipHover } from "../../../shared";
import { displayDateFormatShort } from "../../../constants";
import { CircularProgress, PageLoader } from "../../../shared_elements";
const ProjectTracker = (props) => {
  const { skeletonLoader, setSkeletonLoader } = props
  const { enqueueSnackbar } = useSnackbar();
  let filterOptions
  const lessorInfo = getLocalStorageInfo()?.lessor_list ?? []
  const { filterData, getGlobalConstant } = useGetConstants(['project_departments', 'project_name', 'risk_level'])
  const { isLoading, hasMore, filter, trackerData, getTrackerDetails, setTrackerData } = useGetTrackerList(apiUrl.project_tracker_api, setSkeletonLoader)
  const [anchorEl, setAnchorEl] = useState({ remarksAnchor: null, assetAnchor: null, infoAnchor: null });
  const [popoverData, setPopoverData] = useState({ assetDetail: {}, remarksDetail: "" });
  const [open, setOpen] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [page, setPage] = useState(1)
  useEffect(() => { getTrackerDetails({ page: 1 }, "skeletonLoader"); getGlobalConstant() }, []);
  useEffect(() => { page > 1 && getTrackerDetails({ ...filter, page: page }, "", helperConst.INFINITE_LIST) }, [page]);
  filterOptions = (Object.keys(filterData)?.length > 0) ? { ...projectTrackerFilter, department: { ...projectTrackerFilter.department, options: filterData?.departments }, project_type: { ...projectTrackerFilter.project_type, options: filterData?.projectType }, lessor_id: { ...projectTrackerFilter.lessor_id, options: lessorInfo } } : projectTrackerFilter
  const handleExport = (file) => getTrackerDetails({ ...filter, download: file.extension }, "pageLoader", helperConst.EXPORT);
  const handleItemClick = (index) => index === open ? setOpen("") : setOpen(index)
  const handlePopoverClose = () => setAnchorEl({ assetAnchor: null, remarksAnchor: null, infoAnchor: null });
  const handleAssetPopover = (event, clickedAsset) => { setAnchorEl({ ...anchorEl, assetAnchor: event.currentTarget }); setPopoverData({ assetDetail: clickedAsset }) }
  const handleRemarksPopover = (event, clickedRemarks) => { setAnchorEl({ ...anchorEl, remarksAnchor: event.currentTarget }); setPopoverData({ remarksDetail: clickedRemarks }); }
  const handleInfoPopover = (event,) => { setAnchorEl({ ...anchorEl, infoAnchor: event.currentTarget }) }
  const renderAssets = (assets, index) => {
    const assetArray = assets.length > 3 ? (index === open ? assets : assets.slice(0, 3)) : assets;
    return (<>{assetArray?.length > 0 ? assetArray.map((assetData) => {
      const { asset_type, asset } = assetData;
      const type = asset_type === 1 ? asset.aircraft_type : asset_type === 2 ? asset.engine_type : asset_type === 3 ? asset.apu_type : asset_type === 4 ? asset.lg_position : "";
      const labelType = assetType[asset_type];
      const serialNo = asset_type === 1 ? asset?.msn : asset_type === 2 ? asset?.esn : asset?.serial_number ?? "";
      return (
        <Chip key={index} label={<div>{type ? type : ''}<br />{`${labelType}:${serialNo ? serialNo : ""}`}</div>} size="small" variant="outlined" style={{ padding: '15px 0px' }} onClick={(event) => handleAssetPopover(event, { clickedAsset: assetData, type: type, labelType: labelType, serialNo: serialNo })} />);
    }) : "--"}
      {assets.length > 3 && (<Chip color="primary" onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleItemClick(index); }} clickable={true} label={index !== open ? `+${assets.length - 3} More` : 'Show Less'} size="small" />)}</>);
  };
  const handleLoadMore = () => setPage(page + 1);
  const toggleFilterModal = (status) => setFilterOpen(status)
  const handleFilter = (applyFilter) => {
    if (applyFilter?.startDate && (!moment(applyFilter?.startDate, moment.ISO_8601, true).isValid() || moment(applyFilter?.startDate).year() <= 1400) ) {
      enqueueSnackbar("Please add valid start date.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
      setFilterOpen(true);
    } else if (applyFilter?.endDate && (!moment(applyFilter?.endDate, moment.ISO_8601, true).isValid() ||  moment(applyFilter?.endDate).year() <= 1400)) {
      enqueueSnackbar("Please add valid end date.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
      setFilterOpen(true);
    } else if (applyFilter?.startDate && applyFilter?.endDate && !moment(applyFilter?.endDate).isSameOrAfter(moment(applyFilter?.startDate))) {
      setFilterOpen(true);
      enqueueSnackbar("The end date must be the same as or later than the start date.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
    }else {
      setFilterOpen(false);
      getTrackerDetails({ ...applyFilter, page: 1 }, "pageLoader", helperConst.FILTER);
    }
  }
  return (
    <>
      {isLoading ? <PageLoader /> : null}
      {!skeletonLoader && <>
        <FilterExportPanel filterOpen={filterOpen} toggleFilterModal={toggleFilterModal} handleExport={handleExport} filterOptions={filterOptions} handleFilter={handleFilter} filter={filter} disabled={trackerData?.length === 0 ? true : false}>
          <Tooltip title="Project Status" arrow>
            <li className="list-inline-item align-center cursor-pointer">
              <InfoIcon onClick={handleInfoPopover} color='primary' width={25} height={25} />
            </li>
          </Tooltip>
        </FilterExportPanel>
        <Paper>
          {!trackerData?.length && !isLoading ? (<div style={{ textAlign: "center" }}><EmptyCollection title={"No records found"} /></div>) : <div id="scrollableDiv" style={{ height: window.innerHeight - 190 + "px", "overflow-x": "auto", }}
          >
            <InfiniteScroll
              dataLength={trackerData?.length}
              next={handleLoadMore} hasMore={hasMore}
              loader={<div style={{ textAlign: 'center' }}>Loading...</div>}
              scrollableTarget="scrollableDiv" endMessage={
                <p style={{ textAlign: "center" }}>
                  {!isLoading &&   <b>Yay! You have seen it all</b>}
                </p>
              }>
              <TableWrapper tableHeader={projectTrackerTableHeader}><> {
                trackerData?.map((item, index) => (
                  <TableRow key={index + "projectTable"} hover tabIndex={-1}  >
                    <TableCell style={{ width: '140px' }}>
                      <span className={`project-status-bar ${item.archive_status === 1 ? 'archived' : item.status.label.toLowerCase()}`}></span>
                      <div className="flex-centered" style={{ display: 'flex', flexFlow: "row" }} >
                        <div className="div-lessor-logo">
                          <Tooltip title={item?.lessor?.name}>
                            <div><img alt="Lessor" src={item?.lessor?.logo ? item?.lessor?.logo : item?.lessor?.name} className="lessor-logo" /></div>
                          </Tooltip>
                        </div>
                        <Tooltip title={`${item.progress}%`} arrow>
                          <CircularProgress value={item.progress > 100 ? 100 : item.progress} />
                        </Tooltip>
                        <div style={{ marginLeft: '6px', width: '180px' }}>
                          <span className="project-no">{`#${item?.project_number}`}</span>
                          <div className="lessee-name" id={`project-lesse-name-${item.id}`}>{item?.lessee?.name?.length > 26 ? item.lessee.name.substring(0, 26) + '...' : item?.lessee?.name ? item?.lessee?.name : null}</div>
                          {item.lessee.name && item.lessee.name.length > 26 ? <ToolTipHover placement="top" tagetId={`project-lesse-name-${item.id}`}> {item.lessee.name}</ToolTipHover> : null}
                          <h4 className="project-name">{item?.name}</h4>
                          <h4 className="project-no">{item?.department?.label}</h4>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>{renderAssets(item.assets, index)}</TableCell>
                    <TableCell style={{ width: "120px" }}><div>{item?.status ? (<span className={`project-status ${projectStatusObj[item?.status?.label]}`}>{item?.status?.label}</span>) : null}</div></TableCell>
                    <TableCell style={{ width: "150px" }} > <>{item.project_remarks !== undefined && item.project_remarks !== null && item.project_remarks != '' ? item.project_remarks.length > 45 ? `${item.project_remarks.substr(0, 45)}...` : `${item.project_remarks}` : "--"} <span style={{ color: "#0e7fe1" }}> {item?.project_remarks && <div className="cursor-pointer" onClick={(e) => handleRemarksPopover(e, item)}>..show more</div>}  </span> </> </TableCell>
                    <TableCell style={{ width: "120px" }} >{item.start_date ? moment(item.start_date).format(displayDateFormatShort) : '--'}</TableCell>
                    <TableCell style={{ width: "120px" }}>{item.expected_end_date ? moment(item.expected_end_date).format(displayDateFormatShort) : '--'}</TableCell>
                  </TableRow>
                ))}</></TableWrapper>
            </InfiniteScroll>
          </div>}


        </Paper>
        {/* {!trackerData?.length && !isLoading && !skeletonLoader ? (<div style={{ textAlign: "center" }}><EmptyCollection title={"No records found"} /></div>) : null} */}
        <Popover anchorEl={anchorEl?.remarksAnchor} handleClose={handlePopoverClose} ><RemarksPopover remarks={popoverData?.remarksDetail} trackerData={trackerData} setTrackerData={setTrackerData} /></Popover>
        <Popover anchorEl={anchorEl?.assetAnchor} handleClose={handlePopoverClose} ><AssetPopover assetDetail={popoverData?.assetDetail} /></Popover>
        <Popover anchorEl={anchorEl?.infoAnchor} handleClose={handlePopoverClose}  ><ProjectInfoPopover isProject={true} /></Popover>
      </>}
    </>
  );
};
export default ProjectTracker;