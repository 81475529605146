import React from 'react'
import { Link } from 'react-router';
import { Modal } from '../shared';
import { helperFunc } from '../helperFunc';
import { getLocalStorageInfo } from '../../../utils_v2';
import { imgStoragePath } from '../../../constants';
import appConfig from '../../../config';

const UserInfoModal = (props) => {
    const { userData, handleClose, open } = props;
    const { email, contact_no, project_role, department, city, base_location, experience, skill_sets, special_approvals, licenses, special_trainings, category, fee_rate, type, is_acumen_project_lead } = userData || {};
    const isRootUser = getLocalStorageInfo()?.user.is_root_user || helperFunc.checkSecurityGroup();
    const isTypeThree = type ? type.value === 3 : false;
    const isTypeOne = type ? type.value === 1 : false;
    const userInfoItems = [
        { label: 'Email', value: email },
        { label: 'Phone Number', value: contact_no },
        { label: 'Role', value: project_role },
        { label: 'Department', value: department?.label },
        { label: 'City', value: city },
        { label: 'Country', value: base_location },
        ...(isRootUser && isTypeThree ? [
            { label: 'Experience', value: experience },
            { label: 'Special Approvals', value: special_approvals },
            { label: 'License', value: licenses },
            { label: 'Special Trainings', value: special_trainings },
            {
                label: 'Skill Sets', value: skill_sets.map((skill, index) => {
                    return (
                        <span key={index} style={{ display: 'inline-block', background: '#e6e9f2', padding: '2px 16px', marginRight: '5px', marginBottom: '4px', backgroundColor: '#e6e9f2', border: '1px solid #c4cde3', borderRadius: '16px', color: '#000000', letterSpacing: '.22px' }}>{skill.name}</span>
                    )
                })
            },
        ] : []),
        { label: 'Category', value: category?.label },
        ...(isTypeOne && is_acumen_project_lead ? [{ label: 'Daily Fee', value: fee_rate }] : []),
    ];

    return (
        <Modal divider={false} open={open} maxWidth="md" handleClose={handleClose}>
            <div style={{ width: "450px" }}> <div className="user-detail-modal">
                <div className="user-profile-pic">
                    {
                        userData?.profile_pic ?
                            <img src={userData.profile_pic} alt="img" />
                            :
                            <img src={imgStoragePath + 'user.png'} alt="logo" />
                    }
                    <p>
                        <Link to={`${appConfig.domain.subDomian}users/manage/${userData.slug}`} target="_blank">
                            {userData?.name ? userData.name : null}
                            <i class="fas fa-external-link-alt" style={{ marginLeft: '5px', fontSize: '12px' }}></i>
                        </Link>
                    </p>
                    <p style={{ color: '#000', fontSize: '12px', paddingTop: 0 }}>{userData && userData.designation ? userData.designation : null}</p>
                    <p style={{ padding: '0px', marginTop: '-5px' }}>
                        {userData.rating ? <span style={{ fontSize: '10px', color: '#cccccc', position: 'relative', bottom: '1px' }}>{parseFloat(userData.rating.toFixed(2))} <i class="fa fa-star"></i> </span> : ''}
                    </p>
                </div>
                <ul className="list-unstyled">
                    {userInfoItems?.map((user,index)=>{
                        return (<li className="flex-centered" key={index} style={{borderBottom:"1px solid    #e5e4ef8c"}}>
                            <div className="first-block">
                                <label >
                                    {user?.label}
                                </label>
                            </div>
                            <div className="second-block">
                                <p >
                                   {user?.value ?? "--"}
                                </p>
                            </div>
                        </li>)
                    })}  
                </ul>
            </div></div>
        </Modal>
    )
}

export default UserInfoModal