import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSnackbar } from "notistack";
import {  Paper, Tooltip, } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import { apiUrl, helperConst,manPowerFilter,  } from "..";
import ProjectInfoPopover from "./ProjectInfoPopover";
import UtilizationGraph from "./UtilizationChart";
import FilterExportPanel from "./FilterExportPanel";
import UtilizationIcon from "./UtilizationIcon"
import UserInfoModal from "./UserInfoModal";
import ManpowerPopover from "./ManpowerPopover";
import {  Popover } from "../shared";
import useGetConstants from "../hooks/useGetConstants";
import useManpowerListHook from "../hooks/useManpowerList";
import { EmptyCollection } from "../../../shared";
import { PageLoader, Pagination } from "../../../shared_elements"; 
import CollapsibleTable from "../shared/CollapsibleTable";

const ManPowerTracker = (props) => {
  const { skeletonLoader, setSkeletonLoader } = props
   const { enqueueSnackbar } = useSnackbar();
  let filterOptions
  const [userData, setUserData] = useState({});
  const [filterOpen, setFilterOpen] = useState(false);
  const [modalFlag, setModalFlag] = useState({ utilizationModal: false, userModal: false })
  const [selectedYear, setSelectedYear] = useState()
  const [anchorEl, setAnchorEl] = useState({projectAnchor : null,infoAnchor : null});
  const [projectDetail, setProjectDetail] = useState();
  const { filterData, getGlobalConstant } = useGetConstants(["project_departments", "user_category"])
  const { isLoading, filter, trackerData, fetchManpowerDetails } = useManpowerListHook(apiUrl.manpower_list_api, setSkeletonLoader)
  useEffect(() => { fetchManpowerDetails({ page: 1 }, "skeletonLoader", "",); getGlobalConstant() }, []);
  filterOptions = (Object.keys(filterData)?.length > 0) ?
    { ...manPowerFilter, department: { ...manPowerFilter.department, options: filterData?.departments }, category: { ...manPowerFilter.category, options: filterData?.category } } : manPowerFilter
  const handleExport = (file) => {fetchManpowerDetails({...filter, download: file.extension },"pageLoader", helperConst.EXPORT);}
  const handleUtilizationModal = () => { setModalFlag({ userModal: false, utilizationModal: true }); fetchManpowerDetails({ utilization: true },"pageLoader"); }
  const handleUserDetailModal = (userData) => { setUserData(userData); setModalFlag({ utilizationModal: false, userModal: true }); }
  const handleModalClose = () => setModalFlag({ utilizationModal: false, userModal: false });
  const onChangeDate = (value) => { setSelectedYear(value); fetchManpowerDetails({ utilization_year: value, utilization: true },"pageLoader") }
  const handleProjectPopover = (event, clickedCell) => { setAnchorEl({ ...anchorEl, projectAnchor: event.currentTarget }); setProjectDetail(clickedCell) }
  const handleInfoPopover = (event, ) => { setAnchorEl({ ...anchorEl, infoAnchor: event.currentTarget })}
  const handlePopoverClose = () => setAnchorEl({projectAnchor : null, infoAnchor : null});
  const onChangePage=(event, newPage) => fetchManpowerDetails({...filter, page:newPage+1, per_page:trackerData?.pagination?.per_page}, 'pageLoader')
  const onChangeRowsPerPage=(event) => fetchManpowerDetails({...filter, page:1, per_page: event.target.value}, 'pageLoader')
  const toggleFilterModal =(status)=> setFilterOpen(status)
  const handleFilter = (applyFilter) => {
    if (applyFilter?.startDate && (!moment(applyFilter?.startDate, moment.ISO_8601, true).isValid() ||  moment(applyFilter?.startDate).year() <= 1400)) {
      enqueueSnackbar("Please add valid start date.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
      setFilterOpen(true);
    } else if (applyFilter?.endDate && (!moment(applyFilter?.endDate, moment.ISO_8601, true).isValid() ||  moment(applyFilter?.endDate).year() <= 1400)) {
      enqueueSnackbar("Please add valid end date.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
      setFilterOpen(true);
    } else if (applyFilter?.startDate && applyFilter?.endDate && !moment(applyFilter?.endDate).isSameOrAfter(moment(applyFilter?.startDate))) {
      setFilterOpen(true);
      enqueueSnackbar("The end date must be the same as or later than the start date.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
    }else {
      setFilterOpen(false);
      fetchManpowerDetails({ ...applyFilter, page: 1 }, "pageLoader", helperConst.FILTER);
    }
  }
  return (
    <>
      {isLoading ? <PageLoader /> : null}
      {!skeletonLoader && <><FilterExportPanel filterOpen={filterOpen} toggleFilterModal={toggleFilterModal} handleUtilizationModal={handleUtilizationModal} filterOptions={filterOptions}
        handleExport={handleExport} handleFilter={handleFilter} filter={filter} disabled={trackerData?.users?.length === 0 ? true : false}> <li>
          <UtilizationIcon disabled={trackerData?.users?.length === 0 ? true : false} onClick={handleUtilizationModal} />
        </li>
        <Tooltip title="Project Status" arrow>
            <li className="align-center cursor-pointer" style={{marginLeft : "4px"}}>
              <InfoIcon onClick={handleInfoPopover} color='primary' width={25} height={25} />
            </li>
          </Tooltip>
        </FilterExportPanel>

        <div style={{ clear: "both", width: "100%" }}>
          <Paper>
            <div
              style={{ maxHeight: window.innerHeight - 230 + "px", "overflow-x": "auto" }}
            >
              <CollapsibleTable  tableData={trackerData?.users}  handleUserDetailModal={handleUserDetailModal} handleProjectPopover={handleProjectPopover}/>
            </div> 
            {!trackerData?.users?.length && !isLoading ? (
              <div style={{ textAlign: "center" }}>
                <EmptyCollection title={"No records found"} />
              </div>
            ) : null}
             <Pagination onChangePage={onChangePage} onChangeRowsPerPage={onChangeRowsPerPage} pagination={trackerData?.pagination} />
          </Paper>
          </div>
        {modalFlag?.utilizationModal ? <UtilizationGraph isLoading={isLoading} open={modalFlag?.utilizationModal} handleClose={handleModalClose} symbol="Hours Remaining :"
          yType="percentage"
          toolTipText="Percentage (%)"
          xLabel="Duration (Months)"
          yLabel="Manpower Usage (%)"
          legend="Hours"
          type="low"
          title="Life On Wings Analysis"
          color="#FEF7D1"
          lineColor="#F8D41C"
          onChangeDate={onChangeDate}
          events={[]}
          utilizationData={!isLoading && trackerData}
          selectedYear={selectedYear}
        /> : null}
        {modalFlag?.userModal ? <UserInfoModal isLoading={isLoading} open={modalFlag?.userModal} userData={userData} handleClose={handleModalClose} /> : null}</>}
      <Popover anchorEl={anchorEl?.projectAnchor} handleClose={handlePopoverClose}><ManpowerPopover popoverData={projectDetail} /></Popover>
      <Popover anchorEl={anchorEl?.infoAnchor} handleClose={handlePopoverClose} ><ProjectInfoPopover  /></Popover>    
    </>
  );
};

export default ManPowerTracker;