import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Button, Paper, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Chip, Tooltip, Divider } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { ProjectList, ProjectTabs, ProjectCRU, ProjectStatusPopUp } from '../components';
import { TableListComp, FilterUIComp, ExportManu, EmptyCollection, PageLoader ,DeletePopUp} from '../../../shared_elements';
import { STabsLoader, STableLoader } from '../../../shared_elements/loaders';
import { sLesseeListAc } from '../../../shared/actionCreators';
import { getProjectsApi, getProjectDetailsApi, getProjectFormsApi, exportProjectsApi,deleteProjectList, getProjectAttacmentApi } from '../apiServices';
import { removeEmptyKey, convertFilterObject, onCheckPermission,getLocalStorageInfo } from '../../../utils_v2';
import { projectHd, projectFilterOps, createProject } from '../';
import FilterComponent from '../../../shared_elements/filter_component'
import { GetApp, NotListedLocationOutlined, Visibility } from '@material-ui/icons';
import { trackActivity } from '../../../utils/mixpanel';
import config from '../../../config'
import { castleAirInstance } from '../../../shared_elements/components';
import DeploymentMessage from '../../../shared/components/DeploymentMessage';
import LogoutInfo from '../../../shared/components/LogoutInfo';
import axios, { Axios } from 'axios';
const queryString = require('query-string');
class ProjectListing extends Component {
  constructor(props,location) {
    super(props)
    this.state = {
      projectId:null,
      projectCrud:null,
      projectModal:false,
      skeletonLoader: true,
      pageLoader: false,
      formSubmitLoader: false,
      tabIndex: queryString.parse(props.location.search).project_status
        ? queryString.parse(props.location.search).project_status
        : "all",
      filter: {},
      applyingFilter: {},
      projectStatus:{modal:false, data:null, slug:''},
      projectsInfo: {
        pagination: {},
        list: [],
        projects_count: [],
        permission:{}
      },
      sort: "",
      sort_by: "",
      allForms: [],
      deleteProjectModal:false,
      deleteItem:null,
      attachmentModal: false,
      preview:{modal:false,data:null}       
    }
    this.getProjectsApi = getProjectsApi.bind(this)
    this.deleteProjectList = deleteProjectList.bind(this)
    this.exportProjectsApi = exportProjectsApi.bind(this)
    this.getProjectDetailsApi = getProjectDetailsApi.bind(this)
    this.getProjectFormsApi = getProjectFormsApi.bind(this)
    this.getProjectAttacmentApi = getProjectAttacmentApi.bind(this)
  }
  componentDidMount() {
    let query = { per_page: 10 }
    if (queryString.parse(this.props.location.search)) {
      query = {
        ...query,
        ...queryString.parse(this.props.location.search)
      }
    }
    this.getProjectsApi(this.props, query, 'skeletonLoader');
    this.getProjectFormsApi(this.props, {both:true});
    this.props.getLesseeList();
    trackActivity('Project Management', {
      event_type: 'Page Visited',
      page_title: 'Project Listing',
    })
  }
  createSortHandler = (sortField) => {
    const { sort, sort_by, filter, tabIndex, projectsInfo } = this.state
    this.setState({
      sort: sortField,
      sort_by:
        sortField === sort ? (sort_by === "asc" ? "desc" : "asc") : "asc",
    })
    if (sortField === sort) {
      if (sort_by === "asc") {
        this.getProjectsApi(
          this.props,
          {
            ...filter,
            sort: sortField,
            sort_by: "desc",
            per_page: projectsInfo.pagination.per_page,
            project_status: tabIndex,
          },
          "pageLoader"
        )
      } else {
        this.getProjectsApi(
          this.props,
          {
            ...filter,
            sort: sortField,
            sort_by: "asc",
            per_page: projectsInfo.pagination.per_page,
            project_status: tabIndex,
          },
          "pageLoader"
        )
      }
    } else {
      this.getProjectsApi(
        this.props,
        {
          ...filter,
          sort: sortField,
          sort_by: "asc",
          per_page: projectsInfo.pagination.per_page,
          project_status: tabIndex,
        },
        "pageLoader"
      )
    }
  }

  handleTabChange = (event, newValue) => {
    // this.props.match.history.push({pathname: '/projects',search: '?project_status='+newValue});
    let location = browserHistory.getCurrentLocation()
    location = {
      ...location,
      query: {
        ...location.query,
        project_status: newValue,
      },
    }
    browserHistory.push(location)
    this.getProjectsApi(this.props, location?.query, "pageLoader")
    this.setState((prevState) => ({
      ...prevState,
      tabIndex: newValue,
    }))
    localStorage.setItem('lisitng_url', JSON.stringify(location))
  }
  getResponseBack = (res, opsType) => {
    const { filter, sort, projectsInfo, tabIndex } = this.state;
    if(opsType === 'edit'){
      this.setState(prevState => ({
        ...prevState,
        projectModal:false,
        projectCrud:null,
        projectsInfo: {
          ...prevState.projectsInfo,
          list: prevState.projectsInfo.list.map(item => item.id === res.id ? res : item)
        }
      }));
      this.getProjectsApi(this.props, {...filter, sort: sort, sort_by: 'asc', per_page:projectsInfo.pagination.per_page, project_status: tabIndex}, 'pageLoader');
    }else{
      this.setState({projectModal:false, projectCrud:null, projectId:null});
      this.getProjectsApi(this.props, {...filter, sort: sort, sort_by: 'asc', per_page:projectsInfo.pagination.per_page, project_status: tabIndex}, 'pageLoader');
    }
  }
  setFilterUrl =(query)=>{
    let location = browserHistory.getCurrentLocation()
    location = {
      ...location,
      query: {
        ...query,
        project_status:queryString.parse(this.props.location.search).project_status
        ? queryString.parse(this.props.location.search).project_status
        : "all"
      },
    }
    this.getProjectsApi(this.props, {...location?.query, page:1, per_page:this.state.projectsInfo.pagination.per_page}, 'pageLoader')
    browserHistory.push(location)
    localStorage.setItem('lisitng_url', JSON.stringify(location))
  }
  downloadFile = (data) => {
    axios.get(decodeURIComponent(data.file_url),{timeout: 600000, responseType : 'blob'})
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
        const link = document.createElement('a');
        link.href = url;
        let firstUnderscoreIndex = data.file_name.indexOf('_');
        let fileNameWithoutPrefix = data.file_name.substring(firstUnderscoreIndex + 1);
        const hasExtension = fileNameWithoutPrefix.includes('.');
        const fileNameToDownload = hasExtension ? fileNameWithoutPrefix : data.file_url.split('/').pop();
        link.setAttribute('download',fileNameToDownload);
        document.body.appendChild(link);
        link.click();
    })
  }
  render(){
    const { projectsInfo, projectModal, projectCrud, sort, sort_by, filter, applyingFilter, tabIndex, modal, skeletonLoader, pageLoader, formSubmitLoader, allForms,deleteProjectModal,deleteItem, projectStatus,attachmentModal,attachments,preview } = this.state
    const { lessee } = this.props;
    const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 )
    let filterOptions = {}
    filterOptions = {
      ...projectFilterOps,
      lessee: {
        ...projectFilterOps.lessee,
        options: lessee,
      },
      forms: {
        ...projectFilterOps.forms,
        options: allForms.map((item) => ({
          id: item.id,
          name: `${item.name}-${item.title}`
        })),
      },
    }
    if (castleAirInstance) {
      filterOptions = {
        ...filterOptions,
        'project_remarks': {
          'inputType': 'text',
          'placeholder': 'Description',
          'title': 'Description'
        },
      }
    }
    return(
      <section  className='projects-management-section'>
        <DeploymentMessage />
        <LogoutInfo />
        <div className="projects-list-sec">
          {skeletonLoader ? (
            <STabsLoader count={7} />
          ) : (
            <ProjectTabs
              handleTabChange={this.handleTabChange}
              tabIndex={tabIndex}
              count={projectsInfo.projects_count}
            />
          )}
          {skeletonLoader ? (
            <STableLoader count={9} />
          ) : (
            <Fragment>
            <Paper square className="filter-cta">
                <Grid alignItems='center' container spacing={1}>
                  <Grid item xs={9} >
                  <FilterComponent
                    filter={filter}
                    filterMenu={filterOptions}
                    getResponseBack={(applyFilter) => {this.setFilterUrl(applyFilter);}}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ul className='list-inline project-list-cta'>
                      { onCheckPermission('project_management', 'projects', 'C') ?
                        <li className='list-inline-item'>
                          <Button onClick={() => this.setState({projectCrud:createProject, projectModal:true})} color="primary" variant='contained' size="small">Add Project</Button>
                        </li>:null
                      }
                      { onCheckPermission('project_management', 'projects', 'EXP') ?
                        <li className='list-inline-item'>
                          <ExportManu
                             disabled={projectsInfo.list.length === 0 ? true:false}
                             title="Export" files={[{title:'Excel', extension: 'xls', key:''}]}
                             exportReportFn={(file) => this.exportProjectsApi(this.props, {download:file.extension, ...removeEmptyKey(filter), project_status: tabIndex}, file)}
                          />
                        </li>:null
                      }
                    </ul>
                  </Grid>
                </Grid>
              </Paper>
              <TableListComp
                style={{maxHeight:(window.innerHeight - 230) + 'px'}}
                heads={smbcInstance || castleAirInstance ? projectHd : projectHd.filter(column => column.id !== 'project_remarks')}
                sort={sort}
                sort_by={sort_by}
                data={projectsInfo.list.map((item, index) => (
                  <ProjectList
                    key={index}
                    item={item}
                    editProject={() =>
                      this.getProjectDetailsApi(this.props, item.slug)
                    }
                    projectsInfo={projectsInfo}
                    toggleModalFn={()=>this.setState({deleteProjectModal:true,deleteItem:item})}
                    onChangeProjectStatus={(data)=> this.setState({projectStatus:{modal:true, data:data, slug:item.slug}})}
                    showAttachmentDialog={()=>this.getProjectAttacmentApi(this.props, item.slug)}/>
                ))}
                noRecord={
                  projectsInfo.list.length ? null : (
                    <EmptyCollection title="No records found" />
                  )
                }
                pagination={projectsInfo.pagination}
                onChangePage={(event, newPage) =>
                  this.getProjectsApi(
                    this.props,
                    {
                      ...filter,
                      project_status: tabIndex,
                      page: newPage + 1,
                      per_page: projectsInfo.pagination.per_page,
                    },
                    "pageLoader"
                  )
                }
                onChangeRowsPerPage={(event) =>
                  this.getProjectsApi(
                    this.props,
                    {
                      ...filter,
                      project_status: tabIndex,
                      page: 1,
                      per_page: event.target.value,
                    },
                    "pageLoader"
                  )
                }
                createSortHandler={this.createSortHandler}
              />
            </Fragment>
          )}
        </div>
        { projectStatus.modal ?
          <ProjectStatusPopUp
            toggleModalFn={() => this.setState({projectStatus:{modal:false, data:null}})}
            getResponseBack={() => { this.setState({projectStatus:{modal:false, data:null, slug:''}}); this.getProjectsApi(this.props, {...filter, sort: sort, sort_by: 'asc', per_page:projectsInfo.pagination.per_page, project_status: tabIndex}, 'pageLoader');}}
            projectStatus={projectStatus}
          />:null
        }
        {projectModal ?
          <ProjectCRU
            toggleModalFn={() =>
              this.setState({ projectModal: false, projectCrud: null })
            }
            modal={projectModal}
            projectCrud={projectCrud}
            lessee={lessee}
            getResponseBack={this.getResponseBack}
          />:null
        }
         {
          deleteProjectModal ?
           <DeletePopUp
           modal={deleteProjectModal}
           toggleModalFn={()=>this.setState({deleteProjectModal:false})}
           title="Delete Project"
           content={<h4>Are you sure you want to delete?</h4>}
           deleteRecordFn={()=>this.deleteProjectList(deleteItem)}

          />: null}

        {attachmentModal && (
          <Dialog className='project-management-modal' fullWidth aria-labelledby="scroll-dialog-title" open={attachmentModal}>
            <DialogTitle id="scroll-dialog-title">Attachments</DialogTitle>
            <DialogContent dividers>
              {projectCrud?.files.length > 0 ? (
                <div>
                  {projectCrud?.files.map((attachment, index) => (
                    <>
                      <Grid container spacing={1}>
                        <Grid item md={10}>
                          <p style={{ fontSize: "small", margin: "3px 0 5px 0" }}>{attachment.file_name.substring(attachment.file_name.indexOf('_') + 1)}</p>
                        </Grid>
                        <Grid item md={2}>
                          <ul className="list-inline" style={{ float: 'right' }}>
                            <li className="list-inline-item">
                              <Tooltip placement="bottom-end" title='View Attachment' arrow>
                                <Visibility style={{ marginTop: "5px", cursor: "pointer" }} fontSize="small" color="primary" onClick={() => this.setState({ preview: { modal: true, data: attachment } })} />
                              </Tooltip>
                            </li>
                            <Tooltip placement="bottom-end" title='Download Attachment' arrow>
                              <li className="list-inline-item">
                                <GetApp style={{ marginTop: "5px", cursor: "pointer" }} fontSize="small" color="primary" onClick={()=>this.downloadFile(attachment)} />
                              </li>
                            </Tooltip>
                          </ul>
                        </Grid>
                      </Grid>
                      <Divider />
                    </>                 
                  ))}
                </div>
              ) : (
                <div style={{ textAlign: 'center', border: 'dotted', color: '#d2e1e7', padding: '24px 42px' }}><EmptyCollection title={<h4 style={{ color: '#a0bdc8' }}>No attachments found for this project.</h4>} /></div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ attachmentModal: false })} variant='outlined' size='small' color="primary">Close</Button>
            </DialogActions>
          </Dialog>
        )}
      
        {preview.modal && (
            <Dialog
              open={preview.modal}
              // onClose={() => this.setState({ preview: { modal: false, data: null } })}
              // maxWidth="md"
              fullScreen
            >
              <DialogTitle>Attachment Preview</DialogTitle>
              <DialogContent dividers style={{textAlign:'center'}}>
                {preview?.data?.file_name?.endsWith('.xlsx') || preview.data?.file_name?.endsWith('.xlx') || preview?.data?.file_name?.endsWith('.doc') || preview?.data?.file_name?.endsWith('.docx') ? (
                  <iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(preview?.data?.file_url)} width='100%' height={window.innerHeight} frameborder='0'></iframe>

                ) : preview?.data?.file_name?.endsWith('.jpeg') || preview?.data?.file_name?.endsWith('.jpg') || preview?.data?.file_name?.endsWith('.png') ? (
                  <img
                    src={preview?.data?.file_url}
                    alt="Attachment"
                    style={{ width:'', height: 'auto' }}
                  />
                ) : preview?.data?.file_name?.endsWith('.pdf') ? (
                  <div>
                    <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${encodeURIComponent(preview?.data?.file_url)}`} style={{width: '100%', height: window.innerHeight - 100}}>
                      <param name="type" value="text/html" />
                    </object>
                  </div> ) : (
                  <p>Preview not available for this file type</p>
                )}

              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({ preview: { modal: false, data: null } })} variant="outlined" size="small" color="primary">Close</Button>
              </DialogActions>
            </Dialog>
          )}
        {pageLoader ? <PageLoader /> : null}
      </section>
    )
  }
}
const mapStateToProps = (state) => ({
  lessee: state.sharedReducers.sLesseeList,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLesseeList: () => dispatch(sLesseeListAc()),
  }
}
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(ProjectListing)
)
